<template>
  <div class="v-stack h-stretch">
    <div class="card light">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <label class="text-left required">Date:</label>
          <InputDatePicker v-model="date"></InputDatePicker>
          <label class="text-left required">Amount:</label>
          <input v-model="amount" type="number" />
          <label class="text-left required">Description</label>
          <textarea v-model="description"></textarea>
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  components: {
    InputDatePicker,
  },
  data() {
    return {
      date: moment().toISOString(),
      amount: 0,
      description: "",
    };
  },
  computed: {
    hourly() {
      return (this.monthly * 12) / 252 / 8;
    },
  },
  methods: {
    ...mapActions(["addOneTimePay"]),
    submit() {
      if (this.amount > 0) {
        this.addOneTimePay({
          user: this.id,
          amount: -this.amount,
          fine: false,
          date: this.date,
          description: this.description,
        })
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>